const decodificarJSON = (json) => {
    let temporal = {}
    temporal.paciente = {}
    temporal.medico = {}
    temporal.ubicacion = {}
    temporal.familiar = {}
    console.log(json)

    const fields = json.Fields
    fields?.forEach((field) => {
        const id = field.id.Id
        switch (id) {
            case 'EXPEDIENTE_N':
                temporal.paciente.expediente = field.V
                break;
            case 'LUGAR_DE_NACIMIENTO':
                temporal.paciente.estado = field.V
                break;
            case 'CURP':
                temporal.paciente.curp = field.V
                break;
            case 'NOMBRE_DEL_PACIENTE':
                const nombre = field.V.replace(/  +/g, ' ');
                temporal.paciente.nombre = nombre
                break;
            case 'SEXO':
                temporal.paciente.sexo = field.V
                break;
            case 'FECHA_DE_NACIMIENTO':
                console.log('Fecha de nacimiento', field.V)
                temporal.paciente.fechaNacimiento = field.V
                break;
            case 'undefined_4':
                console.log('Fecha de nacimiento2', field.V)
                temporal.paciente.fechaNacimiento = temporal.paciente.fechaNacimiento + '/' + field.V
                break;
            case 'undefined_3':
                console.log('Fecha de nacimiento3', field.V)
                temporal.paciente.fechaNacimiento = temporal.paciente.fechaNacimiento + '/' + field.V
                break;
            case 'ESTADO_CIVIL':
                temporal.paciente.estadoCivil = field.V
                break;
            case 'OCUPACI_N':
                temporal.paciente.ocupacion = field.V
                break;
            case 'ESCOLARIDAD':
                temporal.paciente.escolaridad = field.V
                break;
            case 'DERECHOHABIENCIA':
                temporal.paciente.derechohabiencia = field.V
                break;
            case 'TEL_FONO':
                temporal.paciente.telefono = field.V
                break;
            case 'DOMICILIO':
                temporal.paciente.domicilio = field.V
                break;
            case 'COLONIA':
                temporal.paciente.colonia = field.V
                break;
            case 'CP':
                temporal.paciente.cp = field.V
                break;
            case 'MUNICIPIO':
                temporal.paciente.municipio = field.V
                break;
            case 'ESTADO':
                temporal.paciente.estado = field.V
                break;
            case 'DIAGN_STICO_DE_INGRESO':
                temporal.paciente.diagnostico = field.V
                break;
            case 'SERVICIO_DE_INGRESO':
                temporal.paciente.servicio = field.V
                break;
            case 'HABITACI_N_N':
                temporal.ubicacion.habitacion = field.V
                break;
            case 'M_DICO_TRATANTE':
                temporal.medico = field.V
                break;
            case 'NOMBRE':
                temporal.paciente.nombreFamiliar = field.V
                break;  
            case 'DOMICILIO_2':
                temporal.paciente.domicilioFamiliar = field.V
                break;  
            case 'RELACI_N':
                temporal.paciente.parentescoFamiliar = field.V
                break;  
            case 'TEL_FONO_2':
                temporal.paciente.telefonoFamiliar = field.V
                break;
            default:
                const a = ''
                break;
        }
    })
    console.log('Datos del PDF', temporal)
    return temporal
}

export { decodificarJSON }