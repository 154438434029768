import Parse from "parse";
import initializeDatabase from '../utils/parseServer';


const hacerIngreso = async (pacienteRecibido, medico, ubicacion, tipo, seleccionUsuario) => {
	initializeDatabase(true);
	let paciente = pacienteRecibido;

	return new Promise(async (resolve, reject) => {


    const user = await Parse.User.current()

	let pacientePointer = {
        __type: "Pointer",
        className: "_User",
        objectId: paciente.objectId,
    };



    const IngresosActivos = Parse.Object.extend("IngresosActivos");
    const ingresos = new IngresosActivos();

    const Ocupacion = Parse.Object.extend("Ocupacion");
    const ocupacion = new Parse.Query(Ocupacion);


 

    const medicoPointer = {
        __type: "Pointer",
        className: "_User",
        objectId: medico.objectId,
    };

    const ocupacionPointer = {
        __type: "Pointer",
        className: "Ocupacion",
        objectId: ubicacion.objectId,
    };


    function actualizarOcupacion(habitacionID) {
        ocupacion.get(habitacionID).then((ocupado) => {
			console.log('Ocupadooo', ocupado);
            ocupado.set("ocupadaPor", pacientePointer);
            ocupado.save();
        });
    }

	function crearPaciente(paciente) {
		return new Promise(async (resolve, reject) => {
			try {
				let username = paciente.names.split(' ').join('') + paciente.lastName1 + (Math.random() + 1).toString(36).substring(7);
				username = username.toLowerCase();
				const password = (Math.random() + 1).toString(36).substring(7);
				const User = Parse.Object.extend("_User");
				console.log('Paciente creado', paciente);
				const userObject = new User(paciente);
				userObject.set("username", username);
				userObject.set("password", password);
				userObject.set("email", paciente.email);
				userObject.set("type", "paciente");
				const user = await userObject.save();
				console.log('User creado', user);
				pacientePointer = {
					__type: "Pointer",
					className: "_User",
					objectId: user.toJSON().objectId,
				};
				resolve(user.toJSON());
			} catch (error) {
				reject(error);
			}
		});
	}


	try {

		// ==> Crear Ingreso
		console.log("Creando ingreso");
		if(!paciente.objectId) {
			paciente = await crearPaciente(paciente);
		}
			const date = new Date();
			ingresos.set("tipo", tipo);
			ingresos.set("estadoActual", tipo);
			ingresos.set("paciente", pacientePointer);
			ingresos.set("admision", date);
			ingresos.set("Alta", false);
			ingresos.set("preAlta", false);
			ingresos.set("cierreEnfermeria", false);
			ingresos.set("cierreAdministrativos", false);
			ingresos.set("cuentaVerificada", false);
			ingresos.set("autor", user);
			paciente.asegurado && ingresos.set("altaSeguro", false)
			ingresos.set("expediente", paciente.expediente);
			ingresos.set("tipoMedico", "Médico titular");
			ingresos.set("medico", medicoPointer);
			ingresos.set("ubicacion", ocupacionPointer);
			ingresos.set("servicio", medico.master);
			ingresos.set('desde', 'web')
			ingresos.set('seleccionUsuario', seleccionUsuario)

			actualizarOcupacion(ubicacion.objectId);

			ingresos.save().then(async (ingreso) => {
				console.log("Ingreso creado", ingreso);
				const Hospitalizacion = Parse.Object.extend("Hospitalizacion");
				const hospitalizacion = new Hospitalizacion();

				const ingresoPointer = {
					__type: "Pointer",
					className: "IngresosActivos",
					objectId: ingreso.id,
				};

				let date = new Date();

				hospitalizacion.set("servicio", ocupacionPointer);
				hospitalizacion.set("ingreso", ingresoPointer);
				hospitalizacion.set("fechaIngreso", date);
				const hospiSave = await hospitalizacion.save()
				console.log("Hospitalizacion creada", hospiSave);
				resolve(ingreso);
			})
		} catch (error) {
			console.log(error);
			reject(error);
		}
	});
}

export default hacerIngreso;