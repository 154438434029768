import Parse from "parse";

const initializeDatabase = (debug = false) => {
  if(debug) {
    Parse.serverURL = 'http://hrsl.fortiddns.com:1337/dev';
    Parse.initialize('SCWASRTWK9Y6AVMP3KFC_TEST_HRSL', 'AEvJgTDxZo6yo80mduCnsGCN3ZIhukr9') 
    } else {
  Parse.serverURL = 'http://hrsl.fortiddns.com:1337/parse';
  Parse.initialize('SCWASRTWK9Y6AVMP3KFC_HRSL', 'AEvJgTDxZo6yo80mduCnsGCN3ZIhukr9') 
    }
}

export default initializeDatabase;