const separarNombres = (str, reverse) => {
    var tokens = str.split(' ');
  
    // Arreglo donde se guardan las palabras del nombre.
    var names = new Array();
  
    // Palabras de apellidos y nombres compuestos
    const special_tokens = new Array('da','de','del','la', 'las','los','mac','mc','van','von','y','i','san','santa');
  
    var prev = "";
  
  
    tokens.forEach(token =>{
      var _token = token.toLowerCase();
      if(special_tokens.includes(_token)){
        prev = `${prev}${firstLetterUpperCase(_token)} `;
      }else{
        names.push(`${prev}${firstLetterUpperCase(_token)}`);
        prev = "";
      }
    });
  
    var num_nombres = names.length;
  
    var nombres = "",
        apellidoPaterno = "",
        apellidoMaterno = '';

        console.log(num_nombres, names)
  
    switch (num_nombres){
      case 0:
        nombres = '';
        break;
      case 1:
        nombres = names[0];
        break;
      case 2:
        nombres = names[0];
        apellidoPaterno = names[1];
        break;
      case 3:
        nombres = names[0];
        apellidoPaterno = names[1];
        apellidoMaterno = names[2];
        break;
      case 4:
        nombres = `${names[0]} ${names[1]}`;
        apellidoPaterno = names[2];
        apellidoMaterno = names[3];
        break;
      default:
        apellidoMaterno = names[-1]
        names.splice(-1);
        apellidoPaterno = names[-1]
        names.splice(-1);
        nombres = names.join(" ");
        break;
    }
   
    if (reverse) {
        switch (num_nombres){
            case 0:
              nombres = '';
              break;
            case 1:
              nombres = names[0];
              break;
            case 2:
              nombres = names[1];
              apellidoPaterno = names[0];
              break;
            case 3:
              nombres = names[2];
              apellidoPaterno = names[0];
              apellidoMaterno = names[1];
              break;
            case 4:
              nombres = `${names[2]} ${names[3]}`;
              apellidoPaterno = names[0];
              apellidoMaterno = names[1];
              break;
            default:
              apellidoMaterno = names[0]
              names.splice(0,1);
              apellidoPaterno = names[0]
              names.splice(0,1);
              nombres = names.join(" ");
              break;
          }
    }
    nombres = nombres?.toUpperCase();
    apellidoMaterno = apellidoMaterno?.toUpperCase()
    apellidoPaterno = apellidoPaterno?.toUpperCase()
    console.log(`Nombres: ${nombres}`);
    console.log(`Apellido paterno: ${apellidoPaterno}`)
    console.log(`Apellido materno: ${apellidoMaterno}`)

    return {nombres, apellidoPaterno, apellidoMaterno}
  }
  
  
  /* Funciones auxiliares */
  
  function firstLetterUpperCase(str){
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  export { separarNombres }