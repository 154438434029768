import logo from '../..//media/Logo.webp';
import { Navigate, useNavigate } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import Parse from 'parse/dist/parse.min.js';
import React, { useEffect, useState } from 'react'
import Lottie from "lottie-react";
import scan from '../../media/scan.json'
import { decodificarJSON } from '../../utils/decodificarJSON';
import { buscarPaciente, buscarMedico } from  '../../utils/buscarPaciente';
import initializeDatabase from '../../utils/parseServer';
var base64 = require('base-64');

function NuevoIngreso() {

    initializeDatabase(true);

    const [currentUser, setCurrentUser] = useState(true)
    const [warning, setWarning] = useState('')
    const [file, setFile] = useState(undefined)
    const [json, setJson] = useState(undefined)
    const [data, setData] = useState(undefined)
    const [readText, setReadText] = useState('')
    const navigate = useNavigate();

    
    const getCurrentUser = async () => {
        setCurrentUser(await Parse.User.current().toJSON())
    }

    useEffect(() => {
        getCurrentUser()
    }, [])


    

    const leerArchivo = async (e) => {
        const reader = new FileReader()
        const file = e.target.files[0];
        setTimeout(() => {
            setWarning('')
        reader.onload = async function (e) {

            let file = e.target.result;
            file = file.replace('data:application/pdf;base64,', '')
            console.log(file)

            const params = { file }
            const json = await Parse.Cloud.run('pruebaPDF', params)
            const info = decodificarJSON(json)
            if (!info.paciente.nombre)  {
                setFile(undefined)
                setWarning('El PDF que seleccionaste no es válido')
                return
            }
            const {paciente, topPacientes} = await buscarPaciente(info.paciente)
            const {medico, topMedicos} = await buscarMedico(info.medico)
            setData({medico, paciente })

            if (medico && paciente) navigate('/confirmarIngreso', {state: { medico, paciente, topMedicos, topPacientes, info, seleccionUsuario: 'automático' }})
            else if (!medico) {
                setWarning('No se encontró al médico en la base de datos')
                setFile(undefined)
            }
            else if (!paciente) navigate('/revisarPaciente', {state: { medico, paciente, topMedicos, topPacientes, info }})
        }
        reader.readAsDataURL(file)
        if(e?.target?.files[0]?.name?.slice(-4) !== '.pdf') 
        {
            setWarning('Debes seleccionar un archivo PDF') 
            return
        }
            setFile(e.target.files[0])
            setReadText('Leyendo archivo')
            setTimeout(() => {
                setReadText('Encontrando al paciente')
            }, 1500)
            setTimeout(() => {
                setReadText('Persiguiendo al médico')
            }, 3000)

    }, 100)
    }


  return (
            <div className="App">
            <header className="App-header">
                {!currentUser && <Navigate to='/' replace='true' /> }
                <img src={logo} className="App-logo" alt="logo" width='100%' height='auto' />
    
                <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label>Selecciona el PDF del expediente</Form.Label>
                    <Form.Control 
                        disabled={file ? true : false}
                        type="file" 
                        onChange={(e) => leerArchivo(e)}
                    />
                </Form.Group>
               
                
                {warning && 
                    <div class="alert alert-warning" role="alert">
                        {warning}
                    </div>
                }
                {file && 
                <>
                <Lottie 
                    animationData={scan} 
                    loop={true}
                    />
                    <p>{readText}</p>
                </>}
                </header>
           
    </div>
  );
}

export default NuevoIngreso;