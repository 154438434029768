import Parse from 'parse/dist/parse.min.js';
var stringSimilarity = require("string-similarity");

let best = undefined
let top = []

const revisarSimilitud = (nombre, nombres, perfiles) => {

    if (perfiles) {
        let nuevosPerfiles = perfiles.sort((a,b) => a.nombreCompleto < b.nombreCompleto ? 1 : -1)
        const nuevosNombres = nuevosPerfiles.map((r) => r.nombreCompleto)
        const matches = stringSimilarity.findBestMatch(nombre, nuevosNombres)
        console.log('Matches', matches)
        const nuevoMatches = matches.ratings.sort((a,b) => a.target < b.target ? 1 : -1)
        nuevoMatches.forEach((item, index) => {
            if(nuevosPerfiles[index]?.nombreCompleto) nuevosPerfiles[index].rating = item.rating
         });
         const top = nuevosPerfiles.sort((a,b) => a.rating < b.rating ? 1 : -1).slice(0,20)
         console.log('Top10', top)
         return {matches, top}
    } else {
        const matches = stringSimilarity.findBestMatch(nombre, nombres)
        const top = matches.ratings.sort((a,b) => a.rating < b.rating ? 1 : -1).slice(0,20)
        return  {matches, top}
    }
}



const buscarPaciente = async (paciente) => {
    console.log('Búsqueda paciente', paciente)
    let regName = paciente.nombre.replace(/  +/g, ' ');
    regName = regName.trim()
    regName = regName.split(' ').join('|');
    console.log('Búsqueda paciente', regName)
    let result = undefined

    const query = new Parse.Query('_User')
    query.limit(10000)
    query.ascending('createdAt')
    query.equalTo('type', 'paciente')
    query.matches('nombreCompleto',regName)

    const perfiles = (await query.find()).map((r) => r.toJSON())
    const perfilesInicial = perfiles
    const nombres = perfiles.map((r) => r.nombreCompleto)
    
    const {top} = revisarSimilitud(paciente.nombre, nombres, perfilesInicial)
    const index = top.findIndex((r) => r.birthday === paciente.fechaNacimiento && r.rating > .8)
    if (index > -1) {
        console.log('Encontradooo', top[index])
        best = top[index]
        return {paciente: best, topPacientes: top}
    } else {
        return {paciente: '', topPacientes: top}
    }
}   

const buscarMedico = async (medico) => {
    let regName = medico.replace(/  +/g, ' ');
    regName = regName.trim()
    regName = regName.split(' ').join('|');
    console.log('Búsqueda médico', regName)
    const query = new Parse.Query('_User')
    query.limit(100)
    query.ascending('createdAt')
    query.equalTo('type', 'medico')
    query.matches('nombreCompleto',regName)
    console.log(medico)

    const perfiles = (await query.find()).map((r) => r.toJSON())
    const nombres = perfiles.map((r) => r.nombreCompleto)

    const {matches, top} = revisarSimilitud(medico, nombres)
    console.log('Medicos', matches)
    if(matches.bestMatch.rating < .8) {
        return {medico: '', topMedicos: top}
    } else {
        const best = perfiles[matches.bestMatchIndex]
        return {medico: best, topMedicos: top}
    }
}

const buscarUbicacion = async (tipoIngreso) => {
    const query = new Parse.Query('Ocupacion')
    switch (tipoIngreso) {
        case 'Cirugía ambulatoria':
            query.startsWith('tipo', 'Recuperación')
            break;
        case 'Cirugía mayor':
            query.startsWith('tipo', 'Habitación')
            break;
        case 'Hospitalización':
            query.matches('tipo', 'Habitación|Unidad de terapia intensiva')
            break;
        case 'Urgencias':
            query.matches('tipo', 'urgencias', 'i')
            break;
            case 'Cunero':
                query.matches('tipo', 'cunero', 'i')
                break;
        default: 
            const a='a'
            break;
    }

    let results = (await query.find()).map((r) => {return {...r.toJSON(), unique: r.toJSON().tipo + ' ' + r.toJSON().ID}})
    results = results.sort((a,b) => a.ID < b.ID ? 1 : -1).sort((a,b) => a.tipo > b.tipo ? 1 : -1).filter((a) => !a.ocupadaPor)

    return results

}

export { buscarPaciente, buscarMedico, buscarUbicacion }