import React from 'react';
import ReactDOM from 'react-dom/client';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import {
  HashRouter,
  Routes,
  Route
 } from "react-router-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import LogIn from './routes/login/login'
import Home from './routes/home/home'
import NuevoIngreso from './routes/ingresos/nuevoIngreso'
import ConfirmarIngreso from './routes/ingresos/confirmacionIngreso'
import RevisarPaciente from './routes/ingresos/revisarPaciente'
import ExitoIngreso from './routes/ingresos/exitoIngreso'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <HashRouter>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="login" element={<LogIn />} />
      <Route path='home' element={<Home />} />
      <Route path='nuevoIngreso' element={<NuevoIngreso />} />
      <Route path='confirmarIngreso' element={<ConfirmarIngreso />} />
      <Route path='revisarPaciente' element={<RevisarPaciente />} />
      <Route path='exitoIngreso' element={<ExitoIngreso />} />
      <Route
          path="*"
          element={
            <main style={{ padding: "1rem" }}>
              <p>No encontramos la página que buscas</p>
            </main>
          }
        />
    </Routes>
  </HashRouter>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
