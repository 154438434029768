import logo from '../..//media/Logo.webp';
import { Link, Outlet, Navigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Parse from 'parse/dist/parse.min.js';
import React, { useEffect, useState } from 'react'
import SidebarMenu from 'react-bootstrap-sidebar-menu';
import { useNavigate } from "react-router-dom";
import initializeDatabase from '../../utils/parseServer';

function App() {

    let navigate = useNavigate();

    initializeDatabase(true);

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [currentUser, setCurrentUser] = useState(true)

    const login = async () => {
        const user = await Parse.User.logIn(username, password);
        console.log(user)
      }
    
    const getCurrentUser = async () => {
        setCurrentUser(await Parse.User.current())
    }

    getCurrentUser()

    const doLogOut = async () => {

        try {
            await Parse.User.logOut()
            getCurrentUser()
            
        }

        catch (error) {
            alert(`Error! ${error.message}`);
            return false;
          }
    }

    const nuevoIngreso = () => {
        return (
            navigate('../nuevoIngreso')
        )
    }



  return (
            <div className="App">
            <header className="App-header">
                {!currentUser && <Navigate to='/' replace='true' /> }
                <img src={logo} className="App-logo" alt="logo" width='100%' height='auto' />
                    <div class="alert alert-warning" role="alert">
                        Ingreso realizado con éxito
                    </div>
                <Button onClick={() => nuevoIngreso()} > Nuevo ingreso </Button>
                <Button variant="danger" onClick={() => doLogOut()} >Cerrar sesión</Button>
            </header>
           
    </div>
  );
}

export default App;