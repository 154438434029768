import logo from '../..//media/Logo.webp';
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button'
import Parse from 'parse/dist/parse.min.js';
import React, { useEffect, useState } from 'react'
import Lottie from "lottie-react";
import scan from '../../media/scan.json'
import { decodificarJSON } from '../../utils/decodificarJSON';
import { buscarPaciente, buscarMedico } from  '../../utils/buscarPaciente';
import ListGroup from 'react-bootstrap/ListGroup';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { separarNombres } from '../../utils/separarNombres';
import moment from 'moment'
import initializeDatabase from '../../utils/parseServer';

function RevisarPaciente() {


 initializeDatabase(true);

    const [currentUser, setCurrentUser] = useState(true)
    const [warning, setWarning] = useState('')
    const [file, setFile] = useState(undefined)
    const [json, setJson] = useState(undefined)
    const [data, setData] = useState(undefined)
    const {state} = useLocation();
    const [medico, setMedico] = useState(state.medico)
    const [paciente, setPaciente] = useState(state.paciente)
    const [pacienteNuevo, setPacienteNuevo] = useState(state.paciente)
    const [info, setInfo] = useState(state.info)
    const {topPacientes, topMedicos} = state
    const navigate = useNavigate();

    console.log('Paciente editado', pacienteNuevo)


    const procesarNombre = () => {
        console.log('Process', info.paciente)
        const {nombres, apellidoMaterno, apellidoPaterno} = separarNombres(info.paciente.nombre, true)
        let pacienteNuevo = info.paciente
        pacienteNuevo.nombres = nombres;
        pacienteNuevo.apellidoMaterno = apellidoMaterno;
        pacienteNuevo.apellidoPaterno = apellidoPaterno;
        pacienteNuevo.fechaNacimiento = moment(pacienteNuevo.fechaNacimiento, 'DD/MM/YYYY').format('YYYY-MM-DD')
        pacienteNuevo.sexo = pacienteNuevo.sexo.substring(0,1).concat(pacienteNuevo.sexo.substring(1).toLowerCase())
        console.log('Paciente procesado', pacienteNuevo)
        pacienteNuevo.nuevo = true
        setInfo({...info, pacienteNuevo})
        setPacienteNuevo(pacienteNuevo)
    }
    
    const getCurrentUser = async () => {
        setCurrentUser(await Parse.User.current())
    }



    useEffect(() => procesarNombre(), [])



    const enviarPacienteNuevo = (e) => {
        e.preventDefault();
        console.log('Paciente recibido', pacienteNuevo)
        const calle = pacienteNuevo.domicilio.split('#')[0].trim()
        const numero = pacienteNuevo.domicilio.split('#')[1].trim()
        const pacienteObject = {
            nombreCompleto: pacienteNuevo.nombres?.trim() + ' ' + pacienteNuevo.apellidoPaterno?.trim() + ' ' + pacienteNuevo.apellidoMaterno?.trim(),
            street: calle,
            colonia: pacienteNuevo.colonia,
            locality: pacienteNuevo.colonia,
            extNum: numero,
            sex: pacienteNuevo.sexo?.substring(0,1).toUpperCase(),
            names: pacienteNuevo.nombres?.trim(),
            city: pacienteNuevo.municipio?.trim(),
            estadoCivil: pacienteNuevo.estadoCivil?.trim(),
            cp: pacienteNuevo.cp?.trim(),
            phone: pacienteNuevo.telefono?.trim(),
            lastName1: pacienteNuevo.apellidoPaterno?.trim(),
            lastName2: pacienteNuevo.apellidoMaterno?.trim(),
            curp: pacienteNuevo.curp?.trim(),
            state: pacienteNuevo.estado?.trim(),
            emergencyFirstName: pacienteNuevo.nombreFamiliar?.trim(),
            emergencyLastName: '',
            emergencyPhone: pacienteNuevo.telefonoFamiliar?.trim(),
            emergencyPartner: pacienteNuevo.parentescoFamiliar?.trim(),
            birthState: pacienteNuevo.estado?.trim(),
            nationality: '',
            expediente: pacienteNuevo.expediente?.trim(),
            birthday: moment(pacienteNuevo.fechaNacimiento, 'YYYY-MM-DD').format('DD/MM/YYYY')
        }

        console.log('Paciente enviado', pacienteObject)

        
        navigate('/confirmarIngreso', {state: { medico, paciente: pacienteObject, topMedicos, topPacientes, info, seleccionUsuario: 'nuevo' }})

    }

    const onPacienteSeleccionado = () => {
        navigate('/confirmarIngreso', {state: { medico, paciente, info, seleccionUsuario: 'seleccionado' }})

    }

  return (
            <div className="App">
            <header className="App-header">
                {!currentUser && <Navigate to='/' replace='true' /> }
                <img src={logo} className="App-logo" alt="logo" width='100%' height='auto' />
                <div class="alert alert-warning" role="alert">
                        No pudimos seleccionar al paciente
                    </div>
                

                <Container>
                    <Row>
                        <Col xs={6}>
                        <p>
                Por favor, selecciona el que corresponde
                </p>
                            <ListGroup>
                                {topPacientes.map((p, index) => 
                                <ListGroup.Item 
                                    href={p.objectId}
                                    active={paciente?.objectId === p?.objectId}
                                    onClick={(e) => 
                                        setTimeout(() => {
                                            setPaciente(topPacientes[index])
                                        }, 100)}
                                >
                                    {p.nombreCompleto && <p>{p.nombreCompleto}</p>}
                                    {p.birthday && <span className="badge bg-secondary">{p.birthday}</span>}
                                </ListGroup.Item>)} 
                            </ListGroup>
                        </Col>
                        <Col xs={2}>
                                    {paciente && <Button variant="primary" onClick={() => onPacienteSeleccionado()}>{'->'}</Button>}
                        </Col>
                        <Col xs={4}>
                        <p>
                        O crea uno nuevo
                        </p>
                        <p >{info.paciente.nombre}</p>
                    <Form onSubmit={(event) => enviarPacienteNuevo(event)}>
                    <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label>Apellido paterno</Form.Label>
                    <Form.Control 
                        onBlur={(e) => setTimeout(() => setPacienteNuevo({...pacienteNuevo, apellidoPaterno: e.target.value}), 100)}
                        disabled={file ? true : false}
                        defaultValue={pacienteNuevo.apellidoPaterno}
                    />
                    <Form.Label>Apellido materno</Form.Label>
                    <Form.Control 
                        onBlur={(e) => setTimeout(() => setPacienteNuevo({...pacienteNuevo, apellidoMaterno: e.target.value}), 100)}
                        disabled={file ? true : false}
                        defaultValue={pacienteNuevo.apellidoMaterno}
                    />
                    <Form.Label>Nombres</Form.Label>
                    <Form.Control 
                        onBlur={(e) => setTimeout(() => setPacienteNuevo({...pacienteNuevo, nombres: e.target.value}), 100)}
                        disabled={file ? true : false}
                        defaultValue={pacienteNuevo.nombres}
                    />
                     <Form.Label>Fecha de nacimiento</Form.Label>
                    <Form.Control 
                        onBlur={(e) => setTimeout(() => setPacienteNuevo({...pacienteNuevo, fechaNacimiento: e.target.value}), 100)}
                        type='date'
                        defaultValue={pacienteNuevo.fechaNacimiento}
                    />
                      <Form.Label>CURP</Form.Label>
                    <Form.Control 
                        onBlur={(e) => setTimeout(() => setPacienteNuevo({...pacienteNuevo, curp: e.target.value}), 100)}
                        defaultValue={pacienteNuevo.curp}
                    />
                <Form.Label>Sexo</Form.Label>
                    <Form.Select
                        onBlur={(e) => setTimeout(() => setPacienteNuevo({...pacienteNuevo, sexo: e.target.value}), 100)}
                        defaultValue={pacienteNuevo.sexo}
                    >
                        <option>Masculino</option>
                        <option>Femenino</option>
                    </Form.Select>
                    <Button variant="primary" type="submit">
                        Continuar
                    </Button>
                </Form.Group>
                </Form>
                       
                        
                        </Col>
                    </Row>
                   
                </Container>
            
               


                
                {warning && 
                    <div class="alert alert-warning" role="alert">
                        {warning}
                    </div>
                }
               
                </header>
           
    </div>
  );
}

export default RevisarPaciente;